<template>
  <div class="d-flex justify-content-start align-items-center product-name">
    <div class="avatar-wrapper">
      <img
        :alt="productName"
        :src="imageSrc"
        v-bind="mainProps"
        rounded
        class="d-inline-block mr-1 mb-1 border"
      />
    </div>
    <!-- <div class="d-flex flex-column">
      <h6 class="text-body text-nowrap">{{ productName }}</h6>
      <small
        class="text-muted text-truncate"
        style="max-height: 2.4em; overflow: hidden"
      >
        {{ sanitizedProductDescription }}
      </small>
    </div> -->
  </div>
</template>

<script>
import { BImg } from "bootstrap-vue";
export default {
  components: {
    BImg,
  },
  data() {
    return {
      mainProps: {
        width: 60,
        class: "m1",
      },
    };
  },
  methods: {
    stripHTMLTags(input) {
      // Use regular expression to remove HTML tags
      return input.replace(/<\/?[^>]+(>|$)/g, "");
    },
  },
  computed: {
    sanitizedProductDescription() {
      // Use a method to remove HTML tags from productDescription
      return this.stripHTMLTags(this.productDescription);
    },
  },
  props: {
    imageSrc: String, // Pass the image source as a prop
    productName: String, // Pass the product name as a prop
    productDescription: String, // Pass the product description as a prop
  },
};
</script>
