export default function useUsersList() {
  const resolveColorEtatProduct = (etat) => {
    if (etat === true) return "secondary";
    if (etat === false) return "primary";
    return "success";
  };

  const resolveTextEtatProduct = (etat) => {
    if (etat === true) return "En Promo";
    if (etat === false) return "Plein Tarif";
    return "Plein Tarif";
  };

  const resolveStatusProduct = (status) => {
    if (status === "publish") return "primary";
    if (status === "private") return "secondary";
    return "primary";
  };
  const resolveTypeProduct = (type) => {
    if (type === "variable") return "primary";
    if (type === "simple") return "secondary";
    return "primary";
  };
  return {
    resolveTypeProduct,
    resolveColorEtatProduct,
    resolveTextEtatProduct,
    resolveStatusProduct,
  };
}
